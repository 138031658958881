const defaultProps = {
	path: '',
	ext: '',
	useSrcSet: true,
}

const useImageGenerator = (file) => {
	/** Let's assign default values */
	const original = { ...defaultProps, ...file }

	/** Make sure we passed an object */
	if (typeof file !== 'object' && Array.isArray(file) && file === null)
		throw new Error('You need to pass an object to useImageGenerator()')

	/** Make sure we have a path */
	if (!original.path) {
		return null
	}

	/** Make sure we know the extension */
	if (!original.ext) throw new Error('You forgot to provide an ext (ie. "png")')

	/** Make sure we know the extension */
	if (original.useWebp) throw new Error('useWebp is deprecated, this is handled by the CND now')

	let img = { ext: '', x1: '', x2: '', src: '', srcset: '' }

	/**
	 * Compute paths
	 */
	img.x1 = `${original.path}.${original.ext}` // ie. '/images/tree.webp'
	img.x2 = `${original.path}@2x.${original.ext}` // ie. '/images/tree@2x.webp'
	img.src = img.x1 // ie. '/images/tree.webp'
	img.srcset = original.useSrcSet ? `${img.x2} 2x, ${img.x1} 1x` : null // ie. '/images/tree@2x.webp 2x, /images/tree.webp 1x'

	return img
}

export default useImageGenerator
