import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { formatToDecimalAmount } from '../../../../../api/checkout/utils'
import { Flex, Text, HStack, VStack } from '@chakra-ui/react'
import { MonetizationOnRounded } from '../../../atoms/Icon'
import useScrollToHash from '../../../../hooks/useScrollToHash'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

const FunderItem = ({ item, role }) => {
	/** Retrieve date at which the event was the last by the creator */
	const lastSeen = useSelector((s) => s.event.lastViewed, shallowEqual)

	/** Compute timezone, default to Winnipeg */
	const date = dayjs(item.createdAt)
	const timezoneProfile = useSelector((s) => s.auth.profile?.location?.timezone, shallowEqual)
	const timezone = timezoneProfile ? timezoneProfile : 'America/Winnipeg'

	/** User-friendly formatting */
	const formattedDate = date.tz(timezone).format('MMMM D, YYYY') // February 14th, 2022

	/** Is the contribution new? */
	const isNew = role == 'creator' ? date.tz(timezone).isAfter(lastSeen) : false

	/** Format amount accordingly */

	const formattedAmount = formatToDecimalAmount(item.amount, '$')

	/**
	 * Name to display for that contribution (Compute name to render (full vs. anonymous) )
	 * */
	const [displayName, setDisplayName] = useState('')

	useEffect(() => {
		if (item.anonymous) setDisplayName('Anonymous')
		else if (item.user) {
			let fn = item.user.profile?.firstName || ''
			let ln = item.user.profile?.lastName || ''
			let fullName = `${fn} ${ln}`
			setDisplayName(fullName)
		}
	}, [item])

	const location = useLocation()
	useScrollToHash({ location, offset: 286 })
	const isHashSelection = location.hash?.replace('#', '') == item.id

	return (
		<>
			<HStack
				id={item.id}
				background={isHashSelection ? 'blueAlpha.200' : 'transparent'}
				w="full"
				px="1rem"
				spacing="0.5rem"
				justifyContent="space-between"
				minH="55px">
				<HStack alignItems="center" spacing="1rem">
					<Flex>
						<MonetizationOnRounded color="#47596A" />
					</Flex>
					<VStack direction="column" spacing="0" alignItems="flex-start">
						<Text fontSize="0.875rem" fontWeight="bold" color="dark">
							{displayName}
						</Text>
						<Text fontSize="0.875rem" color="dark">
							{formattedDate}
						</Text>
					</VStack>
				</HStack>
				<Flex align="center">
					{isNew && (
						<Text
							as="span"
							sx={{
								backgroundColor: '#006666',
								height: '24px',
								display: 'flex',
								lineH: '24px',
								color: 'white',
								borderRadius: 'md',
								fontSize: '0.875rem',
								fontWeight: '600',
								px: '0.5rem',
								pb: '1px',
								alignItems: 'center',
								mr: '0.5rem',
							}}>
							New
						</Text>
					)}
					<Text fontSize="0.875rem" color="dark">
						{formattedAmount}
					</Text>
				</Flex>
			</HStack>
			{/* <Flex
			direction="row"
			justify="between"
			css={`
				min-height: 55px;
				border-bottom: 1px solid var(--global-grayscale-5);
				&:first-child {
					border-top: 1px solid var(--global-grayscale-5);
				}
				&:last-child {
					border-bottom-width: 2px;
				}
			`}>
			<Flex alignItems="center" spacing={1}>
				<Flex>
					<MonetizationOnRoundedIcon
						css={`
							color: var(--global-grayscale-2);
						`}
					/>
				</Flex>

				<Flex direction="column">
					<Typography className="medium">
						<strong>{displayName}</strong>
					</Typography>
					<Typography
						fontSize={12}
						css={`
							line-height: 12px;
						`}>
						{formattedDate}
					</Typography>
				</Flex>
			</Flex>
			

			<Flex spacing={1} alignItems="center">
				{isNew && (
					<span
						css={`
							background-color: #04d898;
							height: 24px;
							display: flex;
							line-height: 24px;
							color: white;
							border-radius: 25px;
							font-size: 13px;
							font-weight: 600;
							padding: 0 0.75rem;
							align-items: center;
						`}>
						New
					</span>
				)}

				<Typography>{formattedAmount}</Typography>
			</Flex>
		</Flex> */}
		</>
	)
}

export default FunderItem
