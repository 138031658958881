import React from 'react'
import FunderItem from '../FunderItem'
import { formatToDecimalAmount } from '../../../../../api/checkout/utils'
import PropTypes from 'prop-types'
import { VStack, Box, Heading, Text, Divider } from '@chakra-ui/react'

const FundersList = ({ collection, role, ...rest }) => {
	/** Calculate total */
	let total = 0
	collection.forEach((el) => (total += el.amount))

	return (
		<VStack spacing="1rem" w="full" {...rest}>
			<Box w="full">
				<Divider />
				<VStack divider={<Divider />} spacing="0">
					{collection.map((el, i) => (
						<FunderItem item={el} key={i} role={role} />
					))}
				</VStack>
				<Divider />
			</Box>
			<VStack w="full" spacing="0.25rem" alignItems="flex-end">
				<Heading fontSize="1.25rem" variant="hero" color="dark">
					Total: {formatToDecimalAmount(total)}
				</Heading>
				<Text fontSize="0.875rem" color="dark">
					Displayed in USD 🇺🇸
				</Text>
			</VStack>
		</VStack>
	)
}

FundersList.propTypes = {
	collection: PropTypes.array.isRequired,
	role: PropTypes.oneOf(['creator', 'recipient']),
}

export default FundersList
